import { createAsync, useLocation } from '@solidjs/router';
import { BreadcrumbItem, Breadcrumbs, Heading, Container, Prose, Page } from '@troon/ui';
import { Show, Suspense, ErrorBoundary } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { Meta, Title } from '@solidjs/meta';
import { getDocument } from '../../api/documents/[docType]/[slug]';
import { NotFoundContent } from '../../../partials/404';

export default function RewardsDocPage() {
	const loc = useLocation();
	const data = createAsync(() => getDocument('document', 'troon-card-terms'), {
		deferStream: true,
	});

	return (
		<NoHydration>
			<ErrorBoundary fallback={<NotFoundContent />}>
				<Suspense>
					<Show when={data()}>
						{(data) => (
							<>
								<Title>{data().title} | Troon</Title>
								<Meta name="description" content={data().title} />

								<Container class="pt-4">
									<Breadcrumbs>
										<BreadcrumbItem href="/card">Troon Card</BreadcrumbItem>
										<BreadcrumbItem href={loc.pathname}>{data()?.title}</BreadcrumbItem>
									</Breadcrumbs>
								</Container>
								<Page>
									<Container size="small" class="flex flex-col gap-8">
										<Heading as="h1">{data()?.title}</Heading>

										<Prose innerHTML={data()?.content} />
									</Container>
								</Page>
							</>
						)}
					</Show>
				</Suspense>
			</ErrorBoundary>
		</NoHydration>
	);
}
